import type { ComponentType } from "react"
import React, { useEffect, useRef } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

const optionData = {
    counter: {
        store: createStore({ value: 0 }),
    },
    allCounter: {
        store: createStore({ value: 0 }),
    },
    hasznalatiTipus: {
        store: createStore({ value: 0 }),
        options: {
            [0]: "All",
            [1]: "Külföldi-belföldi utazás",
            [2]: "Külföldi-belföldi utazás",
            [3]: "Munkás járat",
        },
    },

    ferohely: {
        store: createStore({ value: 0 }),
        options: {
            [0]: "All",
            [1]: "56+1",
            [2]: "55+1+1",
            [3]: "55+1",
            [4]: "54+1+1",
            [5]: "31+1",
            [6]: "30+1",
            [7]: "22+1",
            [8]: "8+1",
        },
    },

    markajelzes: {
        store: createStore({ value: 0 }),
        options: {
            [0]: "All",
            [1]: "Man",
            [2]: "Renault",
            [3]: "Iveco",
            [4]: "Ford",
            [5]: "Vdl",
        },
    },

    euro: {
        store: createStore({ value: 0 }),
        options: {
            [0]: "All",
            [1]: "EURO 1",
            [2]: "EURO 2",
            [3]: "EURO 3",
            [4]: "EURO 4",
            [5]: "EURO 5",
            [6]: "EURO 6",
        },
    },
}

function getFilter(fieldType, store) {
    let value = optionData[fieldType].options[store.value]
    return value === "All" ? "" : value
}

function getFilterFromStore(fieldType) {
    let value =
        optionData[fieldType].options[
            parseInt(localStorage.getItem(fieldType) || "0")
        ]
    return value === "All" ? "" : value
}

function setCounter(setCounterStore) {
    const filterCounter = document.querySelectorAll(
        '[name="house_component"]'
    ).length
    setCounterStore({ value: filterCounter })
}

export function withNoResults(Component): ComponentType {
    return (props) => {
        let [counterStore, _] = optionData.counter.store()
        return (
            <Component
                {...props}
                style={{
                    zIndex: counterStore.value === 0 ? 1 : -1,
                }}
            ></Component>
        )
    }
}

export function withFilterText(Component): ComponentType {
    return (props) => {
        let [allCounterStore, setAllCounter] = optionData.allCounter.store()
        useEffect(() => {
            setAllCounter({
                value: props.children.props.query.from.data.length,
            })
        }, [""])

        return <Component {...props}></Component>
    }
}

export function withFilter(Component): ComponentType {
    return (props) => {
        let [hasznalatiTipusStore, _] = optionData.hasznalatiTipus.store()
        let [ferohelyStore, __] = optionData.ferohely.store()
        let [markajelzesStore, ___] = optionData.markajelzes.store()
        let [euroStore, ____] = optionData.euro.store()

        const hasznalatiTipus = getFilter(
            "hasznalatiTipus",
            hasznalatiTipusStore
        )
        const ferohely = getFilter("ferohely", ferohelyStore)
        const markajelzes = getFilter("markajelzes", markajelzesStore)
        const euro = getFilter("euro", euroStore)
        return (
            <Component
                {...props}
                hasznalatiTipus={hasznalatiTipus}
                ferohely={ferohely}
                markajelzes={markajelzes}
                euro={euro}
            ></Component>
        )
    }
}

export function withFrame(Component): ComponentType {
    return (props) => {
        return <Component {...props} name="house_component"></Component>
    }
}

export function withDeleteFilter(Component): ComponentType {
    return (props) => {
        const [hasznalatiTipusStore, sethasznalatiTipusStore] =
            optionData.hasznalatiTipus.store()
        const [ferohelyStore, setferohelyStore] = optionData.ferohely.store()
        const [markajelzesStore, setmarkajelzesStore] =
            optionData.markajelzes.store()
        const [euroStore, seteuroStore] = optionData.euro.store()

        return (
            <Component
                {...props}
                onClick={(value) => {
                    sethasznalatiTipusStore({ value: 0 })
                    localStorage.setItem("hasznalatiTipus", "0")
                    setferohelyStore({ value: 0 })
                    localStorage.setItem("ferohely", "0")
                    setmarkajelzesStore({ value: 0 })
                    localStorage.setItem("markajelzes", "0")
                    seteuroStore({ value: 0 })
                    localStorage.setItem("euro", "0")
                }}
            ></Component>
        )
    }
}

export function withCounterText(Component): ComponentType {
    return (props) => {
        let [counterStore, _] = optionData.counter.store()
        let [allCounterStore, __] = optionData.allCounter.store()
        props.children.props.children.props.children =
            "Megjelenítve " +
            counterStore.value +
            " busz a jelenlegi szűrési feltételek mellett és összesen " +
            allCounterStore.value +
            " találat van az adatbázisban."
        return <Component {...props}></Component>
    }
}

function getSelector(Component, props, fieldType) {
    const [store, setStore] = optionData[fieldType].store()
    let [counterStore, setCounterStore] = optionData.counter.store()

    setTimeout(() => {
        setCounter(setCounterStore)
    }, 200)

    useEffect(() => {
        let index = parseInt(localStorage.getItem(fieldType) || "0")

        setTimeout(() => {
            setStore({ value: index })
            setTimeout(() => {
                setCounter(setCounterStore)
            }, 200)
        }, 200)

        props.value = index + 1
    }, [""])
    return (
        <Component
            {...props}
            onChange={(value) => {
                setStore({ value: value })
                localStorage.setItem(fieldType, value)
            }}
            value={store.value + 1}
        ></Component>
    )
}

export function hasznalatiTipus(Component): ComponentType {
    return (props) => {
        return getSelector(Component, props, "hasznalatiTipus")
    }
}

export function ferohely(Component): ComponentType {
    return (props) => {
        return getSelector(Component, props, "ferohely")
    }
}

export function markajelzes(Component): ComponentType {
    return (props) => {
        return getSelector(Component, props, "markajelzes")
    }
}

export function euro(Component): ComponentType {
    return (props) => {
        return getSelector(Component, props, "euro")
    }
}
